table,th,td,tr{
    border: 2px solid black;
    border-collapse: collapse;
    text-align: center;
    padding: 5px;
}
table{
    width: 100%;
    overflow-y: scroll;
    margin-bottom: 40px;
}